<template>
  <DoughnutGraphic :options="chartOptions" :data="chartData" :id="id" />
</template>

<script>
export default {
  name: "DoughnutChartServices",
  components: {
    DoughnutGraphic: () => import("@/components/ui/graphs/DoughnutGraphic"),
  },
  computed: {
    chartData() {
      return {
        labels: this.$store.state.stats.lists.salesByService.map((s) => s.type),
        datasets: [
          {
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(0, 255, 128)",
            ],
            data: this.$store.state.stats.lists.salesByService.map(
              (s) => s.type_quantity
            ),
          },
        ],
      };
    },
  },
  mounted() {},
  data() {
    return {
      id: "servicesGraph",

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
};
</script>
<style></style>
